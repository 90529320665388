<template>
    <div role="dialog" v-show="modalStore.show" aria-modal="true" class="fixed inset-0 overflow-y-none z-50 p-2">
        <div v-show="modalStore.show" class="fixed inset-0 bg-black bg-opacity-50"></div>
        <div v-show="modalStore.show" class="relative min-h-screen flex justify-center items-center">
            <div v-show="modalStore.show" class="relative w-full min-h-0 md:max-w-2xl bg-white rounded-3xl p-12">
                <div class="absolute top-0 z-50 right-0 p-2.5">
                    <button type="button" @click="modalStore.hideModal()"
                        class="text-primary text-2xl px-2.5 rounded-full focus:outline-none">
                        x
                    </button>
                </div>
                <p class="font-display text-primary text-2xl font-semibold mb-10">Condividi con un'amica</p>
                <div class="flex space-x-5">
                    <a :href="'sms:?&body=' + url" target="_blank"
                        class="bg-gray-400 rounded-full w-12 h-12 flex items-center justify-center">
                        <img src="/icons/message.svg" class="w-6" />
                    </a>
                    <a :href="'https://api.whatsapp.com/send?text=' + url" target="_blank"
                        class="w-12 h-12 rounded-full bg-[#24d366] flex items-center justify-center">
                        <img src="/icons/whatsapp.svg" class="w-8" />
                    </a>
                    <a :href="'mailto:?body=' + url" target="_blank"
                        class="bg-gray-400 rounded-full w-12 h-12 flex items-center justify-center">
                        <img src="/icons/email.svg" class="w-6" />
                    </a>
                    <a :href="'http://www.facebook.com/share.php?quote=' + url" target="_blank"
                        class="w-12 h-12 rounded-full bg-[#4c6db3] flex items-center justify-center">
                        <img src="/icons/facebook.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const modalStore = useModalStore();
const runtimeConfig = useRuntimeConfig();
const url = encodeURIComponent(runtimeConfig.public.url + '?utm_source=happybox&utm_medium=landing&utm_campaign=happybox_share');
</script>